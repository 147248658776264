html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-height: 100svh;

  font-family: system-ui, sans-serif;
  line-height: 1.4;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

body {
  display: grid;
  width: 1280px;
  box-sizing: content-box;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;

  padding-inline: 1rem;
  max-width: calc(100% - 2rem);
  padding-block-start: 50px;
  background-color: #edf5fc;
  margin: 0;
}

/*
 * HEADERS
 */

header.skeleton {
  background-color: white;
  border-radius: 8px 8px 0 0;
  contain: paint;
}

/* reset link styles */
.skeleton .hero {
  position: relative;
  min-height: 120px;
  display: flex;
}

.skeleton .hero img {
  max-width: 100%;
}
.skeleton .hero picture {
  display: contents;
}

.skeleton .hero-title {
  position: absolute;
  font-size: 3rem;
  font-weight: bold;
  color: white;
  height: calc(25% + 1rem + 8px);
  min-width: 33%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.skeleton .hero-title div {
  background-color: #000a;
  padding: 8px;
  border-radius: 4px;
}

.skeleton .title {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  align-self: center;
  padding-inline: 50px;
}

/*
 * CONTENT
 */

.skeleton-main {
  flex-grow: 1;
  background-color: white;
  border-radius: 0 0 8px 8px;
}

/*
 * Responsive overrides
 */
@media (max-width: 639px) {
  body {
    padding-inline: 0;
  }
}

header.skeleton input {
  display: none;
}

header.skeleton input + div {
  display: none;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding-inline-end: 0.25rem;
}

header.skeleton input + div label {
  font-size: 0;
}

@media (max-width: 650px) {
  body {
    max-width: 100%;
  }

  header.skeleton input + div {
    display: flex;
  }

  header.skeleton input:not(:checked) + div .close {
    display: none;
  }
  header.skeleton input:checked + div .menu {
    display: none;
  }

  header.skeleton input:not(:checked) ~ nav {
    display: none;
  }
  header nav img {
    display: none;
  }
}

@media (min-width: 651px) and (max-width: 800px) {
  header.skeleton input + div {
    position: relative;
  }
  header.skeleton input + div label {
    position: absolute;
    right: 0;
  }
  header.skeleton input:checked + div {
    position: absolute;
    left: 0;
    right: 0;
  }
  header.skeleton input + div a {
    display: none;
  }
}

/**
 * Non-tailwind styles
 */
[type="radio"]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
  background-color: currentColor;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: transparent;
  appearance: none;
  border-radius: 100%;
}

.show-after-delay {
  animation: 1s show;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes show {
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.contain-paint {
  contain: paint;
}

input[type="number"].appearance-none::-webkit-inner-spin-button,
input[type="number"].appearance-none::-webkit-outer-spin-button {
  appearance: none;
}

input[type="number"].appearance-none {
  appearance: textfield; /* Firefox */
}
